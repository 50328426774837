// THIS FILE IS AUTOGENERATED

export interface Version {
  commit: string;
  version: string;
  app: string;
}

export const version = {
  app: "myqq",
  commit: "900fa9b21c287cc99e91ec9a6c1c8128a9cb3c8c",
  version: "1.107.0",
};
